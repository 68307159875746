import React, { useEffect } from 'react';
import '../styles/Projects.css';
import { useNavigate, useLocation } from 'react-router-dom'; 

// 이미지 import
import beeBusImage from '../images/Bus.png';
import btcMarketImage from '../images/n.webp';
import azureImage from '../images/Azu.webp';
import yoyakImage from '../images/yoyak.webp';
import satooriImage from '../images/satoori.webp';
import ssc from '../images/ssc.png';

function Projects() {
  const navigate = useNavigate();
  const location = useLocation();  // 현재 location을 추적

  useEffect(() => {
    // 라우트가 변경될 때마다 상단으로 스크롤 이동
    window.scrollTo(0, 0);
  }, [location]);  // location이 변경될 때마다 실행

  return (
    <section id="projects" className="projects-container">
      <h2>Projects</h2>
      <div className="boxes-container">
        <div className="project-box" onClick={() => navigate('/BeeBus')}>
          <img src={beeBusImage} alt="Bee Bus" className="project-image" />
          <h3 className="project-title">Bee Bus(시각장애인전용 버스앱)</h3>
          <div className="click-indicator"></div>
        </div>
        <div className="project-box" onClick={() => navigate('/BTC')}>
          <img src={btcMarketImage} alt="BTC Market" className="project-image" />
          <h3 className="project-title">BTC MarKet(비트코인을 이용한 거래플랫폼)</h3>
          <div className="click-indicator"></div>
        </div>
        <div className="project-box" onClick={() => navigate('/Azure')}>
          <img src={azureImage} alt="Azure" className="project-image" />
          <h3 className="project-title">Azure & Karlo 기능을 이용한 이미지생성</h3>
          <div className="click-indicator"></div>
        </div>
        <div className="project-box" onClick={() => navigate('/Yoyak')}>
          <img src={yoyakImage} alt="Yoyak" className="project-image" />
          <h3 className="project-title">한국어 텍스트 요약(KoBART)</h3>
          <div className="click-indicator"></div>
        </div>
        <div className="project-box" onClick={() => navigate('/Satoori')}>
          <img src={satooriImage} alt="Satoori" className="project-image" />
          <h3 className="project-title">한국어 사투리 표준어 변환(KoBART)</h3>
          <div className="click-indicator"></div>
        </div>
        <div className="project-box" onClick={() => navigate('/SSC')}>
          <img src={ssc} alt="SSC" className="project-image" />
          <h3 className="project-title">어린이 보호구역 속도제어 시스템</h3>
          <div className="click-indicator"></div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
