import React, { useState } from 'react';
import '../styles/Yoyak.css'; // CSS 연결
import Navbar from './Navbar'; // 네비게이션 바 추가
import im from '../images/yoyak.webp'; 
import giticon from '../images/git.png'; 

import yoyak1 from '../images/yoyak3.png'; 
import yoyak2 from '../images/yoyak4.png'; 
import yoyak3 from '../images/yoyak5.png'; 
import yoyak4 from '../images/yoyak6.png'; 


import Layout from './Footer';

function Yoyak() {
  const [modalImage, setModalImage] = useState(null);

  const handleImageClick = (image) => {
    setModalImage(image); // 클릭한 이미지의 경로를 모달에 전달
  };

  const closeModal = () => {
    setModalImage(null); // 모달 닫기
  };

  return (
    <div>
      <Navbar /> {/* 네비게이션 바 렌더링 */}
      <div className="hero-container">
        <img
          src={im} 
          alt="BeeBus overview"
          className="hero-image"
        />
      </div>
      <div className="Yoyak-container">
        <h1>한국어 텍스트 요약(KoBART)</h1>
        <p>이 프로젝트는 LLM 모델에 대한 이해도를 높이기 위한 프로젝트이며 KoBART를 사용하였습니다</p>
      </div>

      <div className="team">
        <h5>팀 구성</h5>
        <p>개발: 1명</p>
      </div>

      <div className="time">
        <h5>개발 기한</h5>
        <p>3일</p>
      </div>

      <div className="top">
        <h5>주요 기능</h5>
        <p>Text Summarization</p>
      </div>

      <div className="mid">
        <h5>프로젝트 개요</h5>
        <p>
          LLM 모델에 대한 이해도를 높이기 위해 기획한 프로젝트입니다. <br />
          데이터는 AIHub에서 참조하였고 뉴스, 사회, 시사 등 다양한 데이터를 사용하였습니다.
        </p>
      </div>

      <div className="ad">
        <h5>기능 설명</h5>
        <p>긴 문장의 정보를 30% 이내로 요약합니다.</p>
      </div>

      <div className="sp">
        <h5>기술 스택</h5>
        <p>Fine-Tuning</p>
        <p>Data Preprocessing</p>
      </div>

      <div className="pp">
        {[yoyak1, yoyak2, yoyak4, yoyak3].map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Project screenshot ${index + 1}`}
            className="hero-image"
            onClick={() => handleImageClick(image)} // 이미지 클릭 이벤트
          />
        ))}
      </div>

      <button className="bt"><a href='https://github.com/PSS-pr/smartspeedcontrolsystem'>
      <img src={giticon} alt="Button Icon" />
      <p>깃허브</p></a>
    </button>
      <Layout />

      {/* 모달 구현 */}
      {modalImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={modalImage} alt="Modal enlarged view" />
            <button className="close-button" onClick={closeModal}>
              닫기
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Yoyak;
