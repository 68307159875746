// BeeBus.js
import React, { useState } from 'react';
import '../styles/SSC.css'; // CSS 연결
import Navbar from './Navbar'; // 네비게이션 바 추가
import im from '../images/ssc.png'; 
import Layout from './Footer';
import ssc1 from '../images/ssc1.jpg'
import ssc2 from '../images/ssc2.jpg'
import giticon from '../images/git.png'; 

import ssc3 from '../images/ssc3.jpg'
function SSC() {
  const [modalImage, setModalImage] = useState(null);

  const handleImageClick = (image) => {
    setModalImage(image); // 클릭한 이미지의 경로를 모달에 전달
  };

  const closeModal = () => {
    setModalImage(null); // 모달 닫기
  };

  return (
    <div>
            <Navbar /> {/* 네비게이션 바 렌더링 */}
      <div className="hero-container">
        <img 
          src={im} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
      </div>
      <div className="SSC-container">
        <h1>어린이 보호구역 속도제어 시스템</h1>
        <p>
        프로젝트는 차량(RCcar) 를 어린이 보호구역내에서 한계속도를 제어하는 프로젝트입니다 차량(RcCar)는 2가지 조건의 의해 제어됩니다 
        </p>
        </div>
        <div className='team'>
        <h5>팀구성</h5>
        <p>APP & Object Detection: 2명</p>
        <p>IOT: 1명</p>
        </div>

        <div className='time'>
        <h5>개발기한</h5>
        <p>2024.03 ~ 2024.06</p>
        </div>

       <div className='top'>
        <h5>주요 기능</h5>
        <p>실시간 위치 감지 및 속도 제한</p>
        <p>객체 인식(Object Detection) 기반 제어</p>
        <p>RC 차량 제어 및 통신</p>
        </div>

        <div className='mid'>
        <h5>프로젝트 개요</h5>
        <p>
        이 프로젝트는 어린이 보호구역에서 발생할 수 있는 교통사고를 예방하기 위해 설계되었습니다.<br/>
        사용자 위치와 표지판 인식을 통해 차량 속도를 실시간으로 제어하며<br/>
        어린이 보호구역 내에서는 지정된 속도 제한을 강제합니다.<br/>
        RC카를 통해 시스템을 시뮬레이션하며<br/>
        Firebase를 사용한 데이터 통신과 YOLOv5 기반 객체 탐지를 통해 정확한 표지판 인식을 구현합니다.<br/>
        이 시스템은 스마트한 교통 제어를 통해 어린이 안전을 강화하고 사고를 미연에 방지하는 데 목적을 둡니다.  <br/>
          
        </p>
        </div>

        <div className='ad'>
        <h5>기능 설명</h5>
        <p>
        이 프로젝트는 어린이 보호구역 내 사고 예방을 위해 개발된 시스템입니다.<br/>
        사용자가 보호구역 반경 200m 내에 접근하면 앱이 이를 감지해 차량 속도를 50km/h로 제한하고<br/>
        라즈베리파이 카메라를 이용해 30km 속도 제한 표지판을 인식합니다. <br/>
        보호구역 접근과 표지판 인식이 모두 확인되면 차량 속도를 30km/h로 조정합니다.<br/>
        RC카로 차량을 모사하며 Firebase를 통해 실시간 통신 및 제어가 이루어집니다.
        </p>
        </div>
        <div className='sp'>
        <h5>기술 스택</h5>
        <p>Android Studio</p>
        <p>Object Detection</p>
        <p>Raspberry Pi</p>
        <p>Firebase</p>
        <p>RcCar</p>
        <p>YOLOv5 커스텀 학습 모델 활용</p>
        </div>


        <div className="pp">
        {[ssc1, ssc2, ssc3].map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Project screenshot ${index + 1}`}
            className="hero-image"
            onClick={() => handleImageClick(image)} // 이미지 클릭 이벤트
          />
        ))}
      </div>
        
        <button className="bt"><a href='https://github.com/PSS-pr/smartspeedcontrolsystem'>
      <img src={giticon} alt="Button Icon" />
      <p>깃허브</p></a>
    </button>
        <Layout/>
      {/* 모달 구현 */}
      {modalImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={modalImage} alt="Modal enlarged view" />
            <button className="close-button" onClick={closeModal}>
              닫기
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
    
    export default SSC;
