import React, { useState } from 'react';
import '../styles/BTC.css'; // CSS 연결
import Navbar from './Navbar'; // 네비게이션 바 추가
import im from '../images/n.webp'; 
import Layout from './Footer';
import giticon from '../images/git.png'; 

import btc1 from '../images/btc1.png'; 
import btc2 from '../images/btc2.png'; 
import btc3 from '../images/btc3.png'; 
import btc4 from '../images/btc4.gif'; 
function BTC() {
  const [modalImage, setModalImage] = useState(null);

  const handleImageClick = (image) => {
    setModalImage(image); // 클릭한 이미지의 경로를 모달에 전달
  };

  const closeModal = () => {
    setModalImage(null); // 모달 닫기
  };
  return (
    <div>
      <Navbar /> {/* 네비게이션 바 렌더링 */}
      <div className="hero-container">
        <img 
          src={im} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
      </div>
      <div className="BTC-container">
        <h1>BTC MarKet (개인거래사이트)</h1>
        <p>
          비트코인(BTC)를 이용하여 개인간의 거래를 할수있는 플랫폼입니다
        </p>
        </div>

        <div className='team'>
        <h5>팀구성</h5>
        <p>프론트: 1명</p>
        <p>UI: 1명</p>
        <p>백엔드: 1명</p>
        </div>

        <div className='time'>
        <h5>개발기한</h5>
        <p>2023.03 ~ 2022.06</p>
        </div>

       <div className='top'>
        <h5>주요 기능</h5>
        <p>실시간 가격 변동</p>
        <p>API 데이터 수신</p>
        </div>

        <div className='mid'>
        <h5>프로젝트 개요</h5>
        <p>
          요즘 개인간의 중고거래가 활성화가 되어있습니다 이를통해 구매자는 저렴하게 구매하여좋고 판매자는 안쓰는물건을 처분할수있어서 좋습니다 <br/>
          그러나 거래는 현금으로 이루어지며 현재 암호화폐가 23년기준 이슈여서 뉴스에 자주등장합니다 <br/>
          이 2가지를 접목하여 암호화폐로 거래가가능한 플랫폼을 구축하였습니다<br/>
        </p>
        </div>

        <div className='ad'>
        <h5>기능 설명</h5>
        <p>
          사용자는 게시판등에서 물건을 구매할수있고 판매자와 채팅과 예약을 할수있습니다 또한 판매글을 개재할수있습니다<br/>
          업비트 API 코인구매,판매 등 실제 로 가능하게 구현하였습니다 <br/>
          판매시에는 판매금액에 절대값을 입력하고 그금액이 코인가격에 맞춰서 지정되게 됩니다 그코인가격은 실시간 비트코인시세에 영향을 받습니다<br/>
          예약시 에는 내가 보유한 코인의 금액이 구매할 물건보다 이상보유하고있어야합니다<br/>
        </p>
        </div>
        <div className='sp'>
        <h5>기술 스택</h5>
        <p>node.js</p>
        <p>UpBit API</p>
        <p>MySql</p>
        <p>AWS</p>
        <p></p>
        </div>



        <div className="pp">
        {[btc1, btc2, btc3, btc4].map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Project screenshot ${index + 1}`}
            className="hero-image"
            onClick={() => handleImageClick(image)} // 이미지 클릭 이벤트
          />
        ))}
      </div>
        
        <button className="bt"><a href='https://github.com/PSS-pr/Online-trading-platform-using-Bitcoin.'>
      <img src={giticon} alt="Button Icon" />
      <p>깃허브</p></a>
    </button>
        <Layout/>
        {modalImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={modalImage} alt="Modal enlarged view" />
            <button className="close-button" onClick={closeModal}>
              닫기
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
    
    export default BTC;
