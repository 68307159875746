import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Educations from './components/Educations';
import BeeBus from './components/BeeBus'; // BeeBus 컴포넌트 임포트
import BTC from './components/BTC';
import Layout from './components/Footer';
import Yoyak from './components/Yoyak';
import SSC from './components/SSC';
import Satoori from './components/Satoori';
import Azure from './components/Azure';
import './styles/App.css';

// ScrollToTop 컴포넌트 추가
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    // 라우트가 변경될 때마다 상단으로 스크롤
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* 페이지 이동 시 스크롤을 상단으로 이동 */}
      <Routes>
        <Route 
          path="/" 
          element={
            <div>
              <Navbar />
              <Home />
              <About />
              <Skills />
              <Projects />
              <Educations />
              <Layout />
            </div>
          } 
        />
        <Route path="/BeeBus" element={<BeeBus />} /> {/* 경로 수정 */}
        <Route path="/BTC" element={<BTC />} /> {/* 경로 수정 */}
        <Route path="/Azure" element={<Azure />} /> {/* 경로 수정 */}
        <Route path="/Yoyak" element={<Yoyak />} /> {/* 경로 수정 */}
        <Route path="/Satoori" element={<Satoori />} /> {/* 경로 수정 */}
        <Route path="/SSC" element={<SSC />} /> {/* 경로 수정 */}
      </Routes>
    </Router>
  );
}

export default App;
